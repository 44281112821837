import { useAppearance } from '@/hooks/use-appearance';
import { cn } from '@/lib/utils';
import { Moon, Sun } from 'lucide-react';
import { HTMLAttributes } from 'react';

export default function AppearanceToggleTab({ className = '', ...props }: HTMLAttributes<HTMLDivElement>) {
    const { appearance, updateAppearance } = useAppearance();

    return (
        <div className={cn('inline-flex gap-1 ', className)} {...props}>
                <button
                    onClick={() => updateAppearance(appearance === 'light' ? 'dark' : 'light')}
                    className={cn(
                        'flex items-center cursor-pointer rounded-md p-4 transition-colors',
                        appearance === 'light'
                            ? 'bg-white shadow-xs dark:bg-neutral-700 dark:text-neutral-100'
                            : 'text-neutral-500 hover:bg-neutral-200/60 hover:text-black dark:text-neutral-400 dark:hover:bg-neutral-700/60',
                    )}
                >
                  {appearance == 'light' ? <Moon className={" h-4 w-4"} /> : <Sun className={" h-4 w-4"} />}
                </button>
        </div>
    );
}

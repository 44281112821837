import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from '@/components/ui/sidebar';
import { UserInfo } from '@/components/user-info';
import { GuestMenuContent, UserMenuContent } from '@/components/user-menu-content';
import { useIsMobile } from '@/hooks/use-mobile';
import { type SharedData } from '@/types';
import { usePage } from '@inertiajs/react';
import { ChevronsUpDown, UserCircle, UserIcon } from 'lucide-react';
import Link from '@/components/link';
import routes from '@/lib/routes';
import { Avatar } from '@radix-ui/react-avatar';

export function NavUser({ isMini }: { isMini?: boolean }) {
    const { auth } = usePage<SharedData>().props;
    const { state } = useSidebar();
    const isMobile = useIsMobile();

    return (
        <SidebarMenu>
            <SidebarMenuItem>
                <DropdownMenu>
                    {auth.user ? (
                        <>
                            <DropdownMenuTrigger asChild>
                                <SidebarMenuButton size="lg" className="text-sidebar-accent-foreground data-[state=open]:bg-sidebar-accent group">
                                    <UserInfo showName={!isMini} user={auth.user} />
                                    {isMini ? null : (
                                        <ChevronsUpDown className="ml-auto size-4" />
                                    )}
                                </SidebarMenuButton>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                                className="w-(--radix-dropdown-menu-trigger-width) min-w-56 rounded-lg"
                                align="end"
                                side={isMobile ? 'bottom' : state === 'collapsed' ? 'left' : 'bottom'}
                            >
                                <UserMenuContent user={auth.user} />
                            </DropdownMenuContent>
                        </>
                    ) : (
                        <DropdownMenuTrigger asChild>
                            <Link href={routes.login} className="truncate font-medium flex items-center space-x-3">
                                <SidebarMenuButton size="lg" className="text-sidebar-accent-foreground group cursor-pointer">
                                    <Avatar className="h-8 w-8 overflow-hidden items-center justify-center flex">
                                        <UserCircle className={"text-sidebar-accent-foreground/80"} />
                                    </Avatar>

                                    {!isMini && <span>Login / Create account</span>}
                                </SidebarMenuButton>
                            </Link>
                        </DropdownMenuTrigger>
                    )}
                </DropdownMenu>
            </SidebarMenuItem>
        </SidebarMenu>
    );
}

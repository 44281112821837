import { cn } from '@/lib/utils';
import routes from '@/lib/routes';
import { router } from '@inertiajs/react';
import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Briefcase, Code, Database, Layout, MessageSquare, TrendingUp } from 'lucide-react';

export const categories = [
    { id: "trending", label: "Trending", icon: TrendingUp },
    { id: "programming", label: "Programming", icon: Code },
    { id: "design", label: "Design", icon: Layout },
    { id: "business", label: "Business", icon: Briefcase },
    { id: "marketing", label: "Marketing", icon: MessageSquare },
    { id: "data-ai", label: "Data & AI", icon: Database },
    { id: 'product-project', label: 'Product & Project', icon: Layout },
];

interface CategoriesPillsProps {
    categoryFilter?: string;
    useLink?: boolean;
}

export const CategoriesPills = ({ categoryFilter }: CategoriesPillsProps) => {
    // Track the active category in local state
    const [activeCategory, setActiveCategory] = useState("trending");

    // Update local state when either URL or prop changes
    useEffect(() => {

            // Otherwise get from URL
            const searchParams = new URLSearchParams(window.location.search);
            const urlCategory = searchParams.get('category');
            if (urlCategory) {
                setActiveCategory(urlCategory);
            }
    }, [categoryFilter, typeof window !== 'undefined' ? window.location.search : '']);

    const handleClickCategory = (id: string) => {
        // Update local state immediately
        setActiveCategory(id);

        router.get(`${routes.workshops}?category=${id}`);

    };

    return (
        <div className="flex overflow-x-auto pb-2 gap-2 no-scrollbar">
            {categories.map((category) => (
                <Button
                    variant={activeCategory === category.id ? 'default' : 'secondary'}
                    key={category.id}
                    onClick={() => handleClickCategory(category.id)}
                >
                    {category.label}
                </Button>
            ))}
        </div>
    );
};

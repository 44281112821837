import { Breadcrumbs } from '@/components/breadcrumbs';
import { SidebarTrigger } from '@/components/ui/sidebar';
import { type BreadcrumbItem as BreadcrumbItemType } from '@/types';
import AppearanceToggleTab from '@/components/appearance-tabs';
import WorkshopHeaderSearch from '@/components/app/workshop/workshop-header-search';
import { NavUser } from '@/components/nav-user';
import AppLogoIcon from '@/components/app-logo-icon';
import { Search } from 'lucide-react';
import Link from '@/components/link';
import routes from '@/lib/routes';

export function AppSidebarHeader({ breadcrumbs = [] }: { breadcrumbs?: BreadcrumbItemType[] }) {
    return (
        <header className="border-sidebar-border/50 flex h-16 shrink-0 items-center gap-2 border-b px-2 md:px-4 transition-[width,height] ease-linear group-has-data-[collapsible=icon]/sidebar-wrapper:h-12 ">
            <div className="flex items-center gap-2 w-full justify-between">
                <div className={"flex items-center gap-3 flex-1"}>
                    <div className={"flex items-center gap-2"}>
                        <SidebarTrigger className="-ml-1" />
                        <div className={"size-6 sm:hidden"}>
                            <AppLogoIcon useLink />
                        </div>
                    </div>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    <div className={"hidden sm:flex flex-1 max-w-lg"}>
                        <WorkshopHeaderSearch />
                    </div>
                </div>

                <div className={"flex items-center justify-between gap-1"}>
                    <div className={"sm:hidden"}>
                        <Link href={routes.workshops} className={"py-2"}>
                        <Search className=" h-4 w-4" />
                        </Link>
                    </div>
                    <AppearanceToggleTab />
                    <NavUser isMini />
                </div>
            </div>
        </header>
    );
}

import { AppContent } from '@/components/app-content';
import { AppShell } from '@/components/app-shell';
import { AppSidebar } from '@/components/app-sidebar';
import { AppSidebarHeader } from '@/components/app-sidebar-header';
import { type BreadcrumbItem } from '@/types';
import { type PropsWithChildren } from 'react';

export default function AppSidebarLayout({ children, padded = true, breadcrumbs = [], type = "user", showSidebar = true }: PropsWithChildren<{ breadcrumbs?: BreadcrumbItem[], padded?: boolean, type?: "instructor" | "user" | "admin", showSidebar?: boolean }>) {
    return (
        <AppShell variant="sidebar">
            {showSidebar && <AppSidebar type={type} />}
            <AppContent variant="sidebar">
                <AppSidebarHeader breadcrumbs={breadcrumbs} />
                <div className={padded ? 'p-4' : ''}>
                    {children}
                </div>
            </AppContent>
        </AppShell>
    );
}

import { DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator } from '@/components/ui/dropdown-menu';
import { UserInfo } from '@/components/user-info';
import { useMobileNavigation } from '@/hooks/use-mobile-navigation';
import routes from '@/lib/routes';
import { type User } from '@/types';
import { Link } from '@inertiajs/react';
import { LogOut, PlusCircleIcon, Settings } from 'lucide-react';

interface UserMenuContentProps {
    user: User;
    hideName?: boolean;
}

export function UserMenuContent({ user }: UserMenuContentProps) {
    const cleanup = useMobileNavigation();

    return (
        <>
            <DropdownMenuLabel className="p-0 font-normal">
                <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                    <UserInfo user={user} showEmail={true} />
                </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
                <DropdownMenuItem asChild>
                    <Link className="block w-full" href={route('profile.edit')} as="button" prefetch onClick={cleanup}>
                        <Settings className="mr-2" />
                        Settings
                    </Link>
                </DropdownMenuItem>
                {user.is_instructor && (
                    <>
                    <DropdownMenuItem asChild>
                        <Link className="block w-full" href={routes.instructor.dashboard} as="button" prefetch onClick={cleanup}>
                            <PlusCircleIcon className="mr-2" />
                            Instructor Dashboard
                        </Link>
                    </DropdownMenuItem>
                </>
            )}

            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
                <Link className="block w-full" method="post" href={route('logout')} as="button" onClick={cleanup}>
                    <LogOut className="mr-2" />
                    Log out
                </Link>
            </DropdownMenuItem>
        </>
    );
}


export function GuestMenuContent() {

    return (
        <>
            <DropdownMenuLabel className="p-0 font-normal">
                <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                    <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="truncate font-medium">Login</span>
                    </div>
                </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
                <DropdownMenuItem asChild>
                    <Link className="block w-full" href={"#"} as="button"  >
                        <Settings className="mr-2" />
                        Create an account
                    </Link>
                </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
                <Link className="block w-full" method="post"  href={"#"} as="button" >
                    <LogOut className="mr-2" />
                    Login
                </Link>
            </DropdownMenuItem>
        </>
    );
}

import { NavFooter } from '@/components/nav-footer';
import { NavMain } from '@/components/nav-main';
import { NavUser } from '@/components/nav-user';
import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from '@/components/ui/sidebar';
import { type NavItem } from '@/types';
import { Link } from '@inertiajs/react';
import {
    BookOpen,
    BrainCogIcon,
    CirclePlus,
    FolderKanbanIcon,
    FormInputIcon,
    HomeIcon,
    LayoutDashboardIcon,
    LayoutGrid, MessageCircleReplyIcon, PlusCircleIcon,
    StarIcon,
    TagIcon
} from 'lucide-react';
import AppLogo from './app-logo';
import routes from '@/lib/routes';
import { categories } from '@/components/app/workshop/workshop-categories';

const mainNavItems: NavItem[] = [
    {
        title: 'Home',
        href: '/',
        icon: HomeIcon,
    },
    {
        title: 'Browse Workshops',
        href: '/workshops',
        icon: LayoutGrid,
    },
    {
        title: 'My Learning',
        href: '/dashboard',
        icon: BookOpen,
    },
];

const categoriesNavItems: NavItem[] = categories.map((category) => ({
    title: category.label,
    href: `/workshops?category=${category.id}`,
    icon: category.icon,
}));

const learningNavItems: NavItem[] = [
    {
        title: 'Learning Progress',
        href: '/dashboard',
        icon: BrainCogIcon,
    },
    {
        title: 'Projects',
        href: '/dashboard',
        icon: FolderKanbanIcon,
    },
    {
        title: 'Request a Workshop',
        href: '/dashboard',
        icon: StarIcon,
    },
];

const footerNavItems: NavItem[] = [

];

const instructorMainNavItems: NavItem[] = [
    {
        title: 'Dashboard',
        href:  routes.instructor.dashboard,
        icon: LayoutDashboardIcon,
    },
    {
            title: 'Manage Workshops',
            href:  routes.instructor.workshops.list,
            icon: BrainCogIcon,
    },
    {
        title: 'Manage Projects',
        href: routes.instructor.projects.list,
        icon: FolderKanbanIcon,
    },
]

const supportNavItems : NavItem[] = [
    {
        title: 'Contact Support',
        href: 'mailto://support@joinlearnable.com',
        icon: MessageCircleReplyIcon,
        isExternal: true,
    },
    {
        title: 'Request a Feature/Workshop',
        href: 'https://tally.so/r/n9XbaE',
        icon: TagIcon,
        isExternal: true,
    },
    {
        title: 'Drop us a Feedback',
        href: 'https://tally.so/r/nWqMMj',
        icon: FormInputIcon,
        isExternal: true,
    },
];

export function AppSidebar({ type }: { type: 'instructor' | 'user' | 'admin' }) {
    return (
        <Sidebar collapsible="icon" variant="inset">
            <SidebarHeader>
                <SidebarMenu>
                    <SidebarMenuItem>
                        <SidebarMenuButton size="lg" asChild>
                            <AppLogo />
                        </SidebarMenuButton>
                    </SidebarMenuItem>
                </SidebarMenu>
            </SidebarHeader>

            <SidebarContent>
                <div className={"divide-y divide-foreground-border/50 "}>
                   <NavMain title={""} items={type === 'instructor' ? instructorMainNavItems : mainNavItems} />

                    {type !== 'instructor' && (
                        <>
                            <NavMain title={"Categories"} items={categoriesNavItems} />
                            <NavMain title={"Support"} items={supportNavItems} />
                        </>
                    )}
                </div>
            </SidebarContent>

            <SidebarFooter>
                {type !== 'instructor' && (
                    <NavFooter items={footerNavItems} className="mt-auto" />
                )}
                <NavUser />
            </SidebarFooter>
        </Sidebar>
    );
}

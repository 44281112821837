import AppLayoutTemplate from '@/layouts/app/app-sidebar-layout';
import { type BreadcrumbItem } from '@/types';
import { type ReactNode } from 'react';
import PageLayout from '@/layouts/page-layout';

interface AppLayoutProps {
    children: ReactNode;
    breadcrumbs?: BreadcrumbItem[];
    padded?: boolean;
    type?: 'instructor' | 'user' | 'admin';
    showSidebar?: boolean;
}

export default ({ children, breadcrumbs, ...props }: AppLayoutProps) => (
    <PageLayout>
        <AppLayoutTemplate breadcrumbs={breadcrumbs} {...props}>
            {children}
        </AppLayoutTemplate>
    </PageLayout>
);

import routes from '@/lib/routes';
import { Search } from 'lucide-react';

export default function () {
    return (
        <form action={routes.workshops} className={"relative w-full"}>
            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
            <input
                type="search"
                name={'search'}
                placeholder="Search workshops, topics, instructors..."
                className="h-10 w-full rounded-full border border-border bg-muted pl-10 pr-4 focus:border-ring focus:outline-none"
            />
        </form>
    )
}
